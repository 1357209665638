import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import statusModel from "../../models/status.model";
import { Tooltip } from "antd";
import FormControl from "../../components/common/FormControl";
import timezoneModel from "../../models/timezone.model";
import shared from "./Shared";
import datepipeModel from "../../models/datepipemodel";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import environment from "../../environment";
import { toast } from "react-toastify";

const AddEditUser = () => {
  const { id } = useParams();
  const [images, setImages] = useState({ image: "" });
  const [roleOptions, setRoleOptions] = useState([]);
  const [phoneNo, setPhoneNo] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [form, setform] = useState({ 
    fullName: "",
    email: "",
  });
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);
  const inValidEmail = methodModel.emailvalidation(form?.email);
  const formValidation = [ 
    { key: "email", required: true, message: "Email is required", email: true },
  ];

  const timezones = timezoneModel.list;

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);

    if (invalid) return;
    let method = "post";
    let url = shared.addApi;
    let value = {
      ...form,
      countryCode: dialCode,
      mobileNo: phoneNo ? JSON?.parse(phoneNo) : "",
      id: id
    };
    if (id) {
      method = "put";
      url = shared.editApi;
    } else {
      delete value.id;
    }
    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast?.success(res?.message);
        history(`/${shared.url}`);
      }
      loader(false);
    });
  };

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get(`${shared.detailApi}?id=${id}`).then((res) => {
        if (res.success || res?.code == 200) {
          setform({
            ...form,
            fullName: res?.data?.fullName,
            email: res?.data?.email,
          });
          setDialCode(res?.data?.countryCode || "")
          setPhoneNo(res?.data?.mobileNo || "")
        }
         else {
          toast?.error(res?.message)
         }
        loader(false);
      });
    }
  }, [id]);

  const handleChangePhone = (phone, country) => {
    setDialCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
  };

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1">
            <div className="flex items-center mb-8">
              <Tooltip placement="top" title="Back">
                <Link
                  to={`/${shared.url}`}
                  className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all  mr-3"
                >
                  <i className="fa fa-angle-left text-lg"></i>
                </Link>
              </Tooltip>
              <div>
                <h3 className="text-lg lg:text-2xl font-semibold text-[#111827]">
                  {id ? "Edit" : "Add"} {shared.addTitle}
                </h3>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className=" mb-3">
                <FormControl
                  type="text"
                  name="full_name"
                  label="Full Name"
                  value={form.fullName}
                  onChange={(e) => setform({ ...form, fullName: e })}
                  required
                />
              </div>

              <div className="mobile_number "> 
                <label>Mobile number  </label> 
                <PhoneInput
                  placeholder="Mobile Number"
                  className="mobile-input"
                  country={"us"}
                  value={dialCode + phoneNo}
                  enableSearch={true}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                />
              
                {submitted && !phoneNo && !dialCode && (
                  <div className="invalid-feedback d-block">
                    Mobile is required
                  </div>
                )}
              </div>
              <div className=" mb-3">
                <FormControl
                  type="text"
                  name="email"
                  label="Email"
                  value={form.email}
                  onChange={(e) => setform({ ...form, email: e })}
                  required
                  disabled={id ? true : false}
                />
                {form.email && submitted && !inValidEmail && (
                  <div className="invalid-feedback d-block">
                    Please enter valid email
                  </div>
                )}
              </div>
            </div>

            <div className="text-right">
              <button
                type="submit"
                className="text-[#222222d6] bg-[#ffd6b6] bg-[#ffd6b6] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddEditUser;
