import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import { Tooltip } from "antd";
import FormControl from "../../components/common/FormControl";
import Shared from "./Shared";
import ImageUpload from "../../components/common/ImageUpload";
import { toast } from "react-toastify";

const AddEditVideos = () => {
  const history = useNavigate();
  const { id } = useParams();
  const [images, setImages] = useState({ uploadDocument: "" });
  const [submitted, setSubmitted] = useState(false);
  const [form, setform] = useState({
    title: "",
    link: "",
    goalType: "",
    fitness: "",
  });
  const options = Shared.types;
  const fitnessoptions = Shared?.fitTypes;
  const formValidation = [
    { key: "title", required: true, message: "Title  is required" },
  ];
  useEffect(() => {
    if (id) {
      getOneVideo();
    }
  }, [id]);

  const getOneVideo = () => {
    loader(true);
    ApiClient.get(`${Shared.detailApi}?id=${id}`).then((res) => {
      if (res.success == true || res?.code == 200) {
        loader(false);
        setform({
          ...form,
          title: res?.data?.title,
          goalType: res?.data?.goalType,
          fitness: res?.data?.fitness,
          url: res?.data?.url,
        });
      } else {
        loader(false);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    else if (!form?.goalType || !form?.fitness) return;

    let method = "post";
    let url = Shared.addApi;
    let value;
    value = {
      ...form,
    };
    if (id) {
      method = "put";
      url = Shared.editApi;
      value = {
        ...form,
        id: id,
      };
    } else {
      delete value.id;
    }

    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success || res?.code == 200) {
        toast?.success(res?.message);
        history(`/${Shared.url}`);
        loader(false);
      } else {
        loader(false);
      }
    });
  };

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1">
            <div className="flex items-center mb-8">
              <Tooltip placement="top" title="Back">
                <Link
                  to={`/${Shared.url}`}
                  className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all  mr-3"
                >
                  <i className="fa fa-angle-left text-lg"></i>
                </Link>
              </Tooltip>
              <div>
                <h3 className="text-lg lg:text-2xl font-semibold text-[#111827]">
                  {id ? "Edit" : "Add"} {Shared.addTitle}
                </h3>
              </div>
            </div>

            <h3 className="ViewUser mb-3"></h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className=" mb-3">
                <FormControl
                  type="text"
                  name="title"
                  label="Title"
                  value={form.title}
                  onChange={(e) => setform({ ...form, title: e })}
                />
                {submitted && !form.title && (
                  <div className="invalid-feedback d-block">
                    Title is required
                  </div>
                )}
              </div>

              <div className=" mb-3">
                <FormControl
                  type="select"
                  name="goalType"
                  label="Goal type"
                  value={form.goalType}
                  onChange={(e) => {
                    setform({ ...form, goalType: e });
                  }}
                  options={options}
                  theme="search"
                  required
                />
                {submitted && !form.goalType && (
                  <div className="invalid-feedback d-block">
                    Goal type is required
                  </div>
                )}
              </div>
              <div className=" mb-3">
                <FormControl
                  type="select"
                  name="fitness"
                  label="Fitness"
                  value={form.fitness}
                  onChange={(e) => {
                    setform({ ...form, fitness: e });
                  }}
                  options={fitnessoptions}
                  theme="search"
                  required
                />
                {submitted && !form.fitness && (
                  <div className="invalid-feedback d-block">
                    Fitness is required
                  </div>
                )}
              </div>
              <div className=" mb-3">
                <FormControl
                  type="text"
                  name="url"
                  label="Url"
                  value={form.url}
                  onChange={(e) => setform({ ...form, url: e })}
                />
                {submitted && !form.url && (
                  <div className="invalid-feedback d-block">
                    Url is required
                  </div>
                )}
              </div>
            </div>

            <div className="text-right">
              <button
                type="submit"
                className="text-[#222222d6] bg-[#ffd6b6] bg-[#ffd6b6] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddEditVideos;
