import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import { Link } from "react-router-dom";
import "./style.scss";
import AuthLayout from "../../../components/authlayout";
import methodModel from "../../../methods/methods";
import { useDispatch, useSelector } from "react-redux";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { toast } from "react-toastify";
import { login_success } from "../../../redux/actions/user";

const Login = () => {
  const history = useNavigate();
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [remember, setRemember] = useState(false);
  const [password, setPassword] = useState("");
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });
  useEffect(() => {
    if (user && user?.loggedIn) {
      history("/dashboard");
    }
  }, []);
  
  useEffect(() => {
    let r = localStorage.getItem("remember");
    if (r) {
      let data = JSON.parse(r);
      setUsername(data.email);
      setPassword(data.password);
      setRemember(true);
    }
    let email = methodModel.getPrams("email");
    if (email) setUsername(email);
  }, []);

  const hendleSubmit = (e: any) => {
    e.preventDefault();
    let data: any = {
      email: username,
      password,
    };
    let url = "admin/signin";
    loader(true);
    ApiClient.post(url, data).then(async (res) => {
      if (res.success == true || res?.code == 200) {
        if (res?.data?.role === "subAdmin") {
          localStorage?.setItem(
            "permissions",
            JSON?.stringify(res?.data?.Permission)
          );
        }
        localStorage.setItem("token", res?.data?.access_token);
        localStorage.setItem("admindetails", JSON.stringify(res?.data));
        dispatch(login_success(res?.data));
        if (remember) {
          localStorage.setItem("remember", JSON.stringify(data));
        } else {
          localStorage.removeItem("remember");
        }
        toast?.success(res?.message);
        loader(false);
        history("/dashboard");
      } else {
        toast?.error(res?.message);
        loader(false);
      }
    });
  };

  return (
    <>
      <AuthLayout>
        <form
          className="w-11/12 lg:w-8/12 md:w-9/12	mx-auto"
          onSubmit={hendleSubmit}
        >
          <div className="">
            <h1 className="text-[24px] font-semibold text-black ">
              Login to your account
            </h1>
            <span className="flex w-10 h-1bg-[#130264] mt-1"></span>
          </div>

          <div className="mt-5">
            <input
              type="text"
              className="mb-4 w-full text-sm text-[#222]  h-16 p-3 flex items-center gap-2 overflow-hidden bg-transparent border border-[#D9D9D9] rounded-[14px]"
              placeholder="Email"
              value={username}
              onChange={(e) => setUsername(e.target.value)} 
              required
            />
            <div className="relative mb-6">
              <input
                type={eyes.password ? "text" : "password"}
                className="mb-4 w-full text-sm text-[#222]  h-16 p-3 flex items-center gap-2 overflow-hidden bg-transparent border border-[#D9D9D9] rounded-[10px]"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
              {eyes.password ? (
                <FiEye
                  className="top-6 right-3 absolute text-[#222] cursor-pointer"
                  onClick={() => setEyes({ ...eyes, password: !eyes.password })}
                />
              ) : (
                <FiEyeOff
                  className="top-6 right-3 absolute text-[#222] cursor-pointer"
                  onClick={() => setEyes({ ...eyes, password: !eyes.password })}
                />
              )}
            </div>
          </div>

          <div className="flex">
            <label className="flex items-center pointer">
              <input
                type="checkbox"
                checked={remember}
                onChange={(e) => setRemember(e.target.checked)}
                className="mr-2 h-4 w-4 cursor-pointer"
                style={{ accentColor: "#ffd6b6" }}
              />{" "}
              <span className="text-[14px] font-normal text-[#1A1A1A]">
                Remember Me
              </span>
            </label>
            <Link
              className="font-normal  text-[14px] ml-auto text-[#130264]"
              to="/forgotpassword"
            >
              {" "}
              Forgot Password?
            </Link>
          </div>
          <div className="mt-8 flex items-center justify-center">
            <button
              type="submit"
              className="h-11 rounded-[10px] w-full font-semibold text-center text-[#222222d6]   hover:opacity-80 transition-all "
            >
              Sign in
            </button>
          </div>
        </form>
      </AuthLayout>
    </>
  );
};

export default Login;
