import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { featureType } from "../../models/type.model";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import SelectDropdown from "../../components/common/SelectDropdown";
import statusModel from "../../models/status.model";
import { Tooltip } from "antd";
import { toast } from "react-toastify";

const AddEditFeature = () => {
  const { id } = useParams();
  const [form, setform] = useState(featureType);
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [categories, setcategories] = useState();
  const formValidation = [
    { key: "name", required: true, message: "Feature name is required" },
  ];

  useEffect(() => {
    if (id) {
      getOneFeature();
    } else {
      setform(defaultvalue());
    }
  }, []);
  useEffect(() => {
    getCategoires();
  }, []);

  const getOneFeature = () => {
    loader(true);
    ApiClient.get(`feature?id=${id}`).then((res) => {
      if (res.success == true || res?.code == 200) {
        let value = res.data;
        let payload = featureType;

        Object.keys(payload).map((itm) => {
          payload[itm] = value[itm];
        });
        if (value.categoryId) {
          payload.categoryId = value.categoryId;
        }

        setform({
          ...payload,
        });
        loader(false);
      } else {
        loader(false);
        toast?.error(res?.message);
      }
    });
  };

  const getCategoires = () => {
    ApiClient.get(`all/category`, { status: "active" }).then((res) => {
      setcategories(res.data);
    });
  };
  const defaultvalue = () => {
    let keys = { ...featureType };
    Object.keys(featureType).map((itm) => {
      if (itm != "permissions") keys[itm] = "";
    });
    keys.status = "active";
    return keys;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    else if (!form?.categoryId || !form?.status) {
      return;
    }
    let method = "post";
    let url = "feature";
    let value;
    value = {
      ...form,
    };
    if (id) {
      value = {
        ...form,
        id: id,
      };
      method = "put";
      let url = "feature";
    } else {
      delete value.id;
    }

    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success == true || res?.code == 200) {
        toast?.success(res?.message);
        history("/features");
        loader(false);
      } else {
        loader(false);
        toast?.error(res?.message);
      }
    });
  };
  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1">
            <div className="flex items-center mb-8">
              <Tooltip placement="top" title="Back">
                <Link
                  to="/features"
                  className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border  transition-all   mr-3"
                >
                  <i className="fa fa-angle-left text-lg"></i>
                </Link>
              </Tooltip>
              <div>
                <h3 className="text-base font-medium text-[#0A0D14]">
                  {id ? "Edit" : "Add"} Plan Feature
                </h3>
                <p class="text-sm font-normal text-[#75757A]">
                  Here you can see all about your Plan Feature
                </p>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-6">
                <label>
                  Name<span className="star">*</span>
                </label>
                <input
                  placeholder="Feature name"
                  type="text"
                  className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                  value={form.name}
                  onChange={(e) => setform({ ...form, name: e.target.value })}
                />
                {submitted && !form.name && (
                 <div className="text-danger text-[12px] mt-[3px]">
                  Feature  Name is required
                  </div>
                )}
              </div>
              <div className="col-span-12 md:col-span-6">
                <label>
                  Categories<span className="star">*</span>
                </label>
                <div className="custom_dropdown">
                  <SelectDropdown
                    id="statusDropdown"
                    displayValue="name"
                    placeholder="Select Category"
                    intialValue={form.categoryId}
                    result={(e) => {
                      setform({ ...form, categoryId: e.value });
                    }}
                    options={categories}
                  />
                  {submitted && !form.categoryId ? (
                    <div className="text-danger text-[12px] mt-[3px]">
                      Category is Required
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <label>
                  Status<span className="star">*</span>
                </label>
                <div className="custom_dropdown">
                  <SelectDropdown
                    id="statusDropdown"
                    displayValue="name"
                    intialValue={form.status}
                    result={(e) => {
                      setform({ ...form, status: e.value });
                    }}
                    options={statusModel.list}
                  />
                  {submitted && !form.status ? (
                    <div className="text-danger text-[12px] mt-[3px]">
                      Status is Required
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <label>
                  Tooltip<span className="star">*</span>
                </label>
                <input
                  placeholder="Tooltip"
                  type="text"
                  className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                  value={form.tooltip}
                  onChange={(e) =>
                    setform({ ...form, tooltip: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="flex justify-end mt-6">
              <button
                type="submit"
                className=" text-white bg-[#545454] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddEditFeature;
