import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
import "./style.scss";
import loader from "../../methods/loader"; 
import categoryType from "../../models/categoryType.model";
import Html from "./html"; 
import { useNavigate } from "react-router-dom"; 
import environment from "../../environment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Features = (p) => {
  const history = useNavigate();
  let user = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);
  const [filters, setFilter] = useState({
    page: 1,
    count: 10,
    search: "",
    categoryId: "",
  });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0); 
  const [categories, setcategories] = useState();
  const types = categoryType.list;
  
  useEffect(() => {
    getCategoires();
  }, []);

  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({ ...filters, search: searchState.data });
      getData({ search: searchState.data, page: 1 });
    }
  }, [searchState]);

  const getCategoires = () => {
    ApiClient.get(`all/category`).then((res) => {
      setcategories(res.data);
    });
  };

  const getData = (p = {}) => {
    loader(true);
    let filter = { ...filters, ...p };
    ApiClient.get("features", filter).then((res) => {
      if (res.success == true || res?.code == 200) {
        setData(
          res.data.map((itm) => {
            itm.id = itm.id;
            return itm;
          })
        );
        setTotal(res.total);
        loader(false);
      } else {
        loader(false);
        toast?.error(res?.message);
      }
    });
  };

  const clear = () => {
    setFilter({ ...filters, search: "", page: 1 });
    getData({ search: "", page: 1 });
  };

  const deleteItem = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete this feature`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ffd6b6",
      cancelButtonColor: "#d52121",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.delete(`feature?id=${id}`).then((res) => {
          if (res.success == true || res?.code == 200) {
            toast?.success(res?.message);
            clear();
          }
          loader(false);
        });
      }
    });
  };

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  };

  const ChangeStatus = (e) => {
    setFilter({ ...filters, status: e, page: 1 });
    getData({ status: e, page: 1 });
  };

  const statusChange = (itm) => {
    let status = "active";
    if (itm.status == "active") status = "deactive";
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to ${
        status == "active" ? "Activate" : "Deactivate"
      } this feature?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ffd6b6",
      cancelButtonColor: "#d52121",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.put("feature", { id: itm._id, status }).then((res) => {
          if (res.success == true || res?.code == 200) {
            toast?.success("status changed succesfully");
            loader(false);
            getData();
          } else {
            loader(false);
          }
        });
      }
    });
  }; 
  const view = (id) => {
    history(`/features/detail/${id}`);
  };

  const edit = (id) => {
    history(`/features/edit/${id}`);
  };


  const filter = (p = {}) => {
    setFilter({ ...filters, page: 1, ...p });
    getData({ page: 1, ...p });
  }; 

  const isAllow = (key = "") => {
    let permissions = user.Permission;
    let value = permissions?.permissions?.[key];
    if (user.roleId == environment.adminRoleId) value = true;
    return value;
  }; 
 
  return (
    <>
      <Html
        setFilter={setFilter}
        clear={clear}
        view={view}
        edit={edit}
        isAllow={isAllow}
        types={types}
        ChangeStatus={ChangeStatus}
        pageChange={pageChange}
        deleteItem={deleteItem} 
        filters={filters}
        categories={categories}
        filter={filter} 
        data={data}
        total={total}
        statusChange={statusChange}
      />
    </>
  );
};

export default Features;
