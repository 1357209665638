let host = document.location.host;
let apiUrl = "http://195.35.8.196:6080/"; //"https://stageapi.ecometrixs.com/";

const environment = {
  api: apiUrl,
  sasKey:
    "sp=racwdli&st=2024-05-01T12:17:30Z&se=2070-05-01T20:17:30Z&sv=2022-11-02&sr=c&sig=AQ7v4HhuDj%2BeA0pZHQEy2zATZKSwBMpjie%2BhvcaLc6Y%3D",
  // sasurl: "https://skillprobackendstore.blob.core.windows.net",
  sasurl: apiUrl,
  container: "images/category",
  map_api_key: "AIzaSyCbRhC6h9Pp43-5t_Knyrd_ewAdLMIJtCg",
  planTypeId: "65ead4e65cfbfd7a03ed102f",
  apiUrl: apiUrl,
  userRoleId: "666abbecdf0e68f5e9d28a99",
  customerRoleId: "66026ea2f105ac23ab31e48e",
  glRoleId: "65fc4a1e2e22cff912335e12",
  adminRoleId: 1,
  professionType: "65fa7ad79ef95c639effcf1c",
  productTypeId: "64a7d198fa039f179c0320ca",
};

export default environment;
