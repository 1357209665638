const types = [
  { id: "features", name: "Features" },
  { id: "diet", name: "diet" }, 
];

const Shared = {
  check: "Categories",
  title: "Categories",
  addTitle: "Category",
  url: "category",
  types: [...types],
  addApi: "category",
  editApi: "category",
  detailApi: "category",
  listApi: "all/category",
  statusApi: "category",
  deleteApi: "category",
};

export default Shared;
