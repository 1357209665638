import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import methodModel from "../../../methods/methods";
import "./style.scss";
import AuthLayout from "../../../components/authlayout";
import { toast } from "react-toastify"; 

const Resetpassword = () => {
  const history = useNavigate(); 
  const [submitted, setSubmitted] = useState(false);
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const formValidation = [
    {
      key: "confirmPassword",
      minLength: 8,
      confirmMatch: ["confirmPassword", "newPassword"],
    },
    { key: "newPassword", minLength: 8 },
  ];
  const [form, setForm]: any = useState({
    confirmPassword: "",
    newPassword: "",
    code: "",
    id: "",
  });
  useEffect(() => {
    if (localStorage.getItem("token")) {
      history("/dashboard");
    }
  }, []);

  useEffect(() => {
    let prm = {
      id: methodModel.getPrams("id"),
      code: methodModel.getPrams("code"),
    };

    setForm({ ...form, ...prm });
  }, []);
 

  const getError = (key: any) => {
    return methodModel.getError(key, form, formValidation);
  };

  const hendleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    loader(true);
    let payload = {
      password: form.confirmPassword,
      verificationCode: form.code,
      id: form.id,
    };
    ApiClient.put("reset/password", payload).then((res) => {
      if (res.success == true || res?.code == 200) {
        toast.success(res.message);
        history("/login");
        loader(false);
      }
       else {
        loader(false);
        toast?.error(res?.message)
       }
     
    });
  };

  return (
    <>
      <AuthLayout>
        <form className="" onSubmit={hendleSubmit}>
          <div className="text-center mb-4">
            <h3 className="text-left lgtext">New Password</h3>

            <p className="para_forget_new">
              Please create a new password that you don’t use on any other site.
            </p>
          </div>

          <div className="mb-3"> 
            <div className="mb-3">
              <div className="inputWrapper">
                <input
                  type={eyes.password ? "text" : "password"}
                  className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 mb-0 bginput w-full"
                  value={form.newPassword}
                  minLength={9}
                  maxLength={16}
                  onChange={(e) =>
                    setForm({ ...form, newPassword: e.target.value })
                  }
                  placeholder="New Password"
                  required
                />
                <i
                  className={eyes.password ? "fa fa-eye" : "fa fa-eye-slash"}
                  onClick={() => setEyes({ ...eyes, password: !eyes.password })}
                ></i>
              </div>

              {submitted && getError("newPassword").invalid ? (
                <div className="invalid-feedback d-block">
                  Min Length must be 12 characters long
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="inputWrapper"> 
              <div className="inputWrapper">
                <input
                  type={eyes.confirmPassword ? "text" : "password"}
                  className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 mb-0 bginput w-full"
                  value={form.confirmPassword}
                  minLength={9}
                  maxLength={16}
                  onChange={(e) =>
                    setForm({ ...form, confirmPassword: e.target.value })
                  }
                  placeholder="Confirm Password"
                  required
                />
                <i
                  className={
                    eyes.confirmPassword ? "fa fa-eye" : "fa fa-eye-slash"
                  }
                  onClick={() =>
                    setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword })
                  }
                ></i>
              </div>
              {submitted && getError("confirmPassword").err.confirmMatch ? (
                <div className="invalid-feedback d-block">
                  Confirm Password is not matched with New Password
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className=" mt-6">
            <button
              type="submit"
              className="w-full text-white bg-[#ffd6b6]   focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center  mb-4"
            >
              Save
            </button>
          </div> 
        </form>
      </AuthLayout>
    </>
  );
};

export default Resetpassword;
