import React, { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import "./style.scss";
import loader from "../../methods/loader"; 
import Html from "./html";
import { useNavigate } from "react-router-dom";
import environment from "../../environment"; 
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const Roles = (p) => {
  const user = useSelector((state) => state.user);
  const history = useNavigate(); 
  const searchState = { data: "" };
  const [data, setData] = useState([]); 
  const [total, setTotal] = useState(0);  
  const [filters, setFilter] = useState({
    page: 1,
    count: 50,
    search: "",
    catType: "",
  });

  useEffect(() => {
    if (user.loggedIn) {
      setFilter({ ...filters, search: searchState.data });
      getData({ search: searchState.data, page: 1 });
    }
  }, []);   

  const getData = (p = {}) => {
    loader(true);
    let filter = { ...filters, ...p };
    ApiClient.get("getAllRoles", filter).then((res) => {
      if (res.success == true || res?.code == 200) {
        setData(
          res.data.map((itm) => {
            itm.id = itm.id;
            return itm;
          })
        );
        setTotal(res.total);
        loader(false);
      }
       else {
        loader(false);
        toast?.error(res?.message)
       }
     
    });
  };

  const clear = () => {
    let f = {
      search: "",
      status: "",
      page: 1,
    };
    setFilter({ ...filters, ...f });
    getData(f);
  };

  const deleteItem = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete this role.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ffd6b6",
      cancelButtonColor: "#d52121",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.delete(`delete/role?id=${id}`).then((res) => {
          if (res.success == true || res?.code == 200) {
            toast?.success(res?.message)
            loader(false);
            clear();
          }
           else {
            toast?.error(res?.message)
            loader(false);
           }
         
        });
      }
    });
  };

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  };

  const ChangeRole = (e) => {
    setFilter({ ...filters, catType: e, page: 1 });
    getData({ catType: e, page: 1 });
  };
  const ChangeStatus = (e) => {
    setFilter({ ...filters, status: e, page: 1 });
    getData({ status: e, page: 1 });
  }; 

  const statusChange = (itm) => {
    if (!isAllow("editRoles")) {
      return;
    }
    let status = "active"; 
    if (itm.status == "active") status = "deactive";
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to ${
        status == "active" ? "Activate" : "Deactivate"
      } this role.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ffd6b6",
      cancelButtonColor: "#d52121",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.put(`role`, { id: itm.id, status }).then(
          (res) => {
            if (res.success || res?.code == 200) {
              toast?.success("Status Changed successfully")
              getData();
              loader(false);
            }
             else {
              loader(false);
              toast?.error(res?.message)
             }
           
          }
        );
      }
    });
  };

  const view = (id) => {
    history(`/roles/detail/${id}`);
  };

  const edit = (id) => {
    history(`/roles/edit/${id}`);
  }; 

  const isAllow = (key = "") => {
    let permissions = user.Permission;  
    let value = permissions?.permissions?.[key];
    if (user.roleId == environment.adminRoleId) value = true;
    return value;
  };

  return (
    <>
      <Html
        clear={clear}
        view={view} 
        isAllow={isAllow}
        edit={edit}   
        ChangeRole={ChangeRole}
        ChangeStatus={ChangeStatus}
        pageChange={pageChange} 
        deleteItem={deleteItem} 
        filters={filters} 
        data={data}
        total={total}
        statusChange={statusChange}
      />
    </>
  );
};

export default Roles;
