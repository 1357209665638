const types = [
    { id: "Loss Weight", name: "Loss Weight" },
    // { id: "Build Muscle", name: "Build Muscle" }, 
    // { id: "Be Healthier", name: "Be Healthier" }, 
    // { id: "Increase Energy", name: "Increase Energy" }, 
    // { id: "Manage Glucose", name: "Manage Glucose" }, 
    // { id: "Improve Sleep", name: "Improve Sleep" }, 
  ];

   const fitTypes =[
    { id: "Muscles", name: "Muscles" },
    { id: "Arms", name: "Arms" }, 
    { id: "Legs", name: "Legs" },  
   ]
  
  const Shared = {
    check: "Categories",
    title: "Video Urls",
    addTitle: "Video url",
    url: "video",
    types: [...types],
    addApi: "addFitnessVideo",
    editApi: "editVideo",
    detailApi: "getExercisebyId",
    listApi: "getAllVideo",
    statusApi: "category",
    deleteApi: "deleteVideo",
    fitTypes: [...fitTypes]
  };
  
  export default Shared;
  