import React from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "antd";
import { HiOutlineArrowDown } from "react-icons/hi";
import { FiEdit3, FiPlus } from "react-icons/fi";
import { BsTrash3 } from "react-icons/bs";

const Html = ({
  sorting,
  tab,
  edit,
  reset,
  ChangeStatus,
  statusChange,
  pageChange,
  deleteItem,
  filters,
  dynamicStyle = false,
  className = null,
  data,
  total,
  isAllow,
  view,
  filter,
  setFilter,
  clear,
}) => {
  return (
    <Layout>
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-base font-medium text-[#0A0D14]">Food List</h3>
          <p class="text-sm font-normal text-[#75757A]">
            Here you can see all about your Food List
          </p>
        </div> 
      </div>

      <div className="shadow-box w-full bg-white rounded-[16px] mt-6">
        <div className="flex items-center p-4 justify-end">
          {/* <form
            class="flex items-center max-w-sm"
            onSubmit={(e) => {
              e.preventDefault();
              filter();
            }}
          >
            <label for="simple-search" class="sr-only">
              Search
            </label>
            <div class="relative w-full">
              <input
                type="text"
                id="simple-search"
                value={filters.search}
                onChange={(e) => {
                  setFilter({ ...filters, search: e.target.value });
                }}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-[#ffd6b6]block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-orange-500 dark:focus:border-orange-500 pr-10"
                placeholder="Search"
                required
              />
              {filters?.search && (
                <i
                  className="fa fa-times absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 text-sm"
                  aria-hidden="true"
                  onClick={(e) => clear()}
                ></i>
              )}
            </div>
            <button
              type="submit"
              class="p-2.5 m-2 text-sm font-medium text-[#222222d6] bg-blue-700 rounded-lg border border-[#ffd6b6] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <svg
                class="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span class="sr-only">Search</span>
            </button>
          </form> */}
          <div className="dropdown1 addDropdown1">
            <Menu as="div" className="relative list_box_active_state ml-auto">
              <div>
                <Menu.Button className="flex items-center w-full border justify-center rounded-[8px] bg-white py-[9px] px-[12px] text-[14px]   font-normal  text-[#525866]  hover:bg-gray-200 font-medium -tracking-[0.084px] border-[#DFE1E6]">
                  {filters.status
                    ? filters.status == "deactive"
                      ? "Inactive"
                      : filters.status
                    : "All Status"}

                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={`${
                    dynamicStyle ? "" : "max-h-60"
                  }  focus:!outline-[#ffd6b6] focus:!outline text-sm absolute z-40 ${
                    className ? className : " min-w-[260px]"
                  }  right-0 shadow-lg !py-2 !mt-1.5 overflow-auto bg-white  rounded-lg scrollbar`}
                >
                  <div className="mt-2">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={
                            filters.status == ""
                              ? "text-gray-700 block px-4 py-2 text-sm active"
                              : "text-gray-700 block px-4 py-2 text-sm"
                          }
                          onClick={() => ChangeStatus("")}
                        >
                          All Status
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={
                            filters.status == "active"
                              ? "text-gray-700 block px-4 py-2 text-sm active"
                              : "text-gray-700 block px-4 py-2 text-sm"
                          }
                          onClick={() => ChangeStatus("active")}
                        >
                          Active
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item className="divide-y-1 divide-gray-800 pt-1  mt-2">
                      <p className="border-t"></p>
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={
                            filters.status == "Inactive"
                              ? "text-gray-700 block px-4 py-2 text-sm active"
                              : "text-gray-700 block px-4 py-2 text-sm"
                          }
                          onClick={() => ChangeStatus("deactive")}
                        >
                          Inactive
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

          {filters.interval || filters.currencyId ? (
            <>
              <button
                className="bg-primary border border-solid border-[#27A376] shadow-[1px 2px 4px 0px #0d0d121f] py-[10px] px-[12px] leading-[20px] mr-[8px] flex items-center   hover:bg-[#71B55C] text-[14px]  rounded-[8px] text-[#222222d6]  font-medium hover:no-underline"
                onClick={reset}
              >
                Reset
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
        <>
          <div className="mx-[24px] pt-[24px] border-t border-[#E2E4E9]">
            <div className="scrollbar w-full overflow-auto">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-4 bg-[#ffd6b93b]"
                      onClick={(e) => sorting("name")}
                    >
                      Name{" "}
                      <span className="ml-2">
                        <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                      </span>
                    </th>
                    <th scope="col" className="px-6 py-4 bg-[#ffd6b93b]">
                      Status
                    </th>
                   
                  </tr>
                </thead>
                <tbody className="planDrag">
                  {data.map((itm, index) => {
                    return (
                      <tr>
                        <td
                          className="px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]"
                          onClick={(e) => view(itm.id)}
                        >
                          {itm.name}
                        </td>
                        <td className="px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]">
                          {" "}
                          <div
                            className={`user_hours ${itm.status}`}
                            onClick={() => statusChange(itm)}
                          >
                            <Tooltip placement="top" title="Active / Inactive">
                              <span className="contract">
                                {itm.status == "deactive"
                                  ? "inactive"
                                  : "active"}
                              </span>
                            </Tooltip>
                          </div>
                        </td>

                        {/* dropdown */}
                        <td className="px-[6px] py-4 text-[#0A0D14] font-[400] -tracking-[0.084px] text-[13px] border-b border-[#E2E4E9]">
                          <div className="flex items-center  gap-1.5">
                            {isAllow("editPlan") ? (
                              <Tooltip placement="top" title="Edit">
                                <a
                                  className="border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl"
                                  onClick={(e) => edit(itm.id)}
                                >
                                  <FiEdit3 />
                                </a>
                              </Tooltip>
                            ) : (
                              <></>
                            )}

                            {!itm.activeSubscription ? (
                              <>
                                {isAllow("deletePlan") ? (
                                  <Tooltip placement="top" title="Delete">
                                    <span
                                      className="border cursor-pointer  hover:opacity-70 rounded-lg bg-[#ffd6b614] w-10 h-10 !text-primary flex items-center justify-center text-lg"
                                      onClick={() => deleteItem(itm.id)}
                                    >
                                      <BsTrash3 />
                                    </span>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )} 
                          </div>
                        </td> 
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>

        {total == 0 ? (
          <div className="no-data-found text-center p-5">
            <p className="uppercase	text-sm tracking-widest ">No Data Found</p>
          </div>
        ) : (
          <></>
        )}
        {/* {total > filters.count ? ( */}
          <div className="paginationWrapper  mt-15"> 
            <Pagination
              currentPage={filters.pageNumber}
              totalSize={total}
              sizePerPage={filters.pageSize}
              changeCurrentPage={pageChange}
            />
          </div>
        {/* ) : (
          <></>
        )}  */}
      </div>
    </Layout>
  );
};

export default Html;
