import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
import "./style.scss";
import loader from "../../methods/loader"; 
import categoryType from "../../models/categoryType.model";
import Html from "./html"; 
import { useNavigate } from "react-router-dom"; 
import environment from "../../environment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const FoodList = (p) => {
  let user = useSelector((state) => state.user); 
  const [filters, setFilter] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortOrder: "asc",
    dataType : "Branded"
    // search: "", 
  }); 
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0); 
  const types = categoryType.list;
  const history = useNavigate(); 
  useEffect(() => {
      if (user && user.loggedIn) {
          setFilter({ ...filters,  })
          getData({  data, pageNumber: 1 })
      }
  }, [])
  

  const getData = (p = {}) => {
    loader(true);
    let filter = { ...filters, ...p };
    ApiClient.get("foodList", filter).then((res) => {
      if (res.success == true || res?.code == 200) {
        setData(
          res.data.map((itm) => {
            itm.id = itm.id;
            return itm;
          })
        );
        setTotal(res.total);
        loader(false);
      } else {
        loader(false);
        toast?.error(res?.message);
      }
    });
  };

  const clear = () => {
    setFilter({ ...filters,  pageNumber: 1 });
    getData({ pageNumber: 1, sortOrder:"asc"});
  };

  const filter = (p = {}) => {
    setFilter({ ...filters, pageNumber: 1, ...p });
    getData({ pageNumber: 1, ...p });
  };

  const reset = () => {
    let p = {
    
    };
    setFilter({ ...filters, pageNumber: 1, ...p });
    getData({ pageNumber: 1, ...p });
  };

  const deleteItem = (id) => {  
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete this plan`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ffd6b6",
      cancelButtonColor: "#d52121",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.delete(`subscription-plan?id=${id}`).then((res) => {
          if (res.success == true || res?.code == 200) {
            toast?.success(res?.message)
            clear();
          }
          loader(false);
        }); 
      }
    });
  };


  const pageChange = (e) => {
    setFilter({ ...filters, pageNumber: e });
    getData({ pageNumber: e,sortOrder:"asc" });
  }; 
  const ChangeStatus = (e) => {
    setFilter({ ...filters, status: e, pageNumber: 1 });
    getData({ status: e, pageNumber: 1 });
  };
  
  

  const statusChange = (itm) => { 
    let status = "active";
    if (itm.status == "active") status = "deactive"; 
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to ${
        status == "active" ? "Activate" : "Deactivate"
      } this plan?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ffd6b6",
      cancelButtonColor: "#d52121",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true)
        ApiClient.put("subscription-plan", { id: itm.id, status }).then(res => {
            if (res.success == true || res?.code == 200) {
              toast?.success("status changed succesfully")
              loader(false)
                getData()
            }
            else {
              loader(false)
            } 
        })
       
      }
    });
  };

  const view = (id) => {
    history(`/plans/detail/${id}`);
  };

  const edit = (id) => {
    history(`/plans/edit/${id}`);
  };  

  const isAllow = (key = "") => {
    let permissions = user.Permission;
    let value = permissions?.permissions?.[key];
    if (user.roleId == environment.adminRoleId) value = true;
    return value;
  }; 

  return (
    <>
      <Html
        view={view}
        edit={edit}
        isAllow={isAllow}
        filter={filter} 
        reset={reset}
        types={types} 
        ChangeStatus={ChangeStatus}
        pageChange={pageChange}
        deleteItem={deleteItem}
        filters={filters}
        data={data}
        total={total}
        statusChange={statusChange} 
        setFilter={setFilter}
        clear={clear}
      
      />
    </>
  );
};

export default FoodList;
