import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import methodModel from "../../../methods/methods";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/user";
import { toast } from "react-toastify";
import Layout from "../../../components/global/layout";

const ChangePassword = (p) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [form, setForm] = useState({
    confirmPassword: "",
    currentPassword: "",
    newPassword: "",
  });
  const formValidation = [
    {
      key: "confirmPassword",
      minLength: 8,
      confirmMatch: ["confirmPassword", "newPassword"],
    },
    { key: "currentPassword", minLength: 8 },
    { key: "newPassword", minLength: 8 },
  ];
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });
  const getError = (key) => {
    return methodModel.getError(key, form, formValidation);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    loader(true);
    let payload = {
      currentPassword: form.currentPassword,
      newPassword: form.newPassword,
      confirmPassword: form?.confirmPassword,
    };
    ApiClient.put("change/password", payload).then((res) => {
      if (res.success == true) {
        loader(false);
        toast?.success(res?.message);
        dispatch(logout());
        localStorage.removeItem("token");
        history("/login");
      } else {
        loader(false);
      }
    });
  };

  return (
    <>
    <Layout>
      <div className="wrapper_section">
        <div className="main-title mb-4 lg:mb-8">
          <h3 className="text-lg lg:text-2xl font-semibold text-[#111827] mb-6">
            Change Password
          </h3>
        </div>
        <div className=" grid grid-cols-12">
          <div className="col-span-12 lg:col-span-7 md:col-span-8 input_form p-6 shadow-box overflow-hidden rounded-lg bg-white">
            <form onSubmit={handleSubmit}>
              <div className="items-center ">
                <div className="">
                  <div className="flex flex-col md:flex-row  items-center gap-4 mb-6">
                    <label className="text-typo text-base font-medium w-96">
                      Current Password<span className="start">*</span>
                    </label>
                    <div className="w-full">
                      <div className="relative ">
                        <input
                          type={eyes.currentPassword ? "text" : "password"}
                          className="shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary border border-[#e3e3e3]"
                          value={form.currentPassword}
                          maxLength="20"
                          placeholder="Enter current password"
                          onChange={(e) =>
                            setForm({
                              ...form,
                              currentPassword: e.target.value,
                            })
                          }
                          required
                        />
                        <div className="absolute right-2 top-3 cursor-pointer text-grey-500 text-sm">
                          <i
                            className={
                              eyes.currentPassword
                                ? "fa fa-eye text-gray-400"
                                : "fa fa-eye-slash text-gray-400"
                            }
                            onClick={() =>
                              setEyes({
                                ...eyes,
                                currentPassword: !eyes.currentPassword,
                              })
                            }
                          ></i>
                        </div>
                      </div>
                      {submitted && getError("currentPassword").invalid ? (
                        <div className="invalid-feedback d-block">
                          Min Length must be 8 characters long
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row  items-center gap-4 mb-6 ">
                    <label className="text-typo text-base font-medium w-96">
                      New Password<span className="start">*</span>
                    </label>

                    <div className=" w-full">
                      <div className="relative ">
                        <input
                          type={eyes.password ? "text" : "password"}
                          className="shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary border border-[#e3e3e3]"
                          value={form.newPassword}
                          placeholder="Enter new password"
                          minLength={9}
                          maxLength={16}
                          onChange={(e) =>
                            setForm({ ...form, newPassword: e.target.value })
                          }
                          required
                        />
                        <div className="absolute right-2 top-3 cursor-pointer text-grey-500 text-sm">
                          <i
                            className={
                              eyes.password
                                ? "fa fa-eye text-gray-400"
                                : "fa fa-eye-slash text-gray-400"
                            }
                            onClick={() =>
                              setEyes({ ...eyes, password: !eyes.password })
                            }
                          ></i>
                        </div>
                      </div>
                      {submitted && getError("newPassword").invalid ? (
                        <div className="invalid-feedback d-block">
                          Min Length must be 8 characters long
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row  items-center gap-4 mb-6">
                    <label className="text-typo text-base font-medium w-96">
                      Confirm Password<span className="start">*</span>
                    </label>

                    <div className="relative w-full ">
                      <input
                        type={eyes.confirmPassword ? "text" : "password"}
                        className="shadow-box bg-white w-full text-sm placeholder:text-gray-500 rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2 !ring-primary !outline-primary border border-[#e3e3e3]"
                        placeholder="Enter Confirm Password"
                        value={form.confirmPassword}
                        minLength={9}
                        maxLength={16}
                        onChange={(e) =>
                          setForm({ ...form, confirmPassword: e.target.value })
                        }
                        required
                      />
                      <div className="absolute right-2 top-3 cursor-pointer text-grey-500 text-sm">
                        <i
                          className={
                            eyes.confirmPassword
                              ? "fa fa-eye text-gray-400"
                              : "fa fa-eye-slash text-gray-400"
                          }
                          onClick={() =>
                            setEyes({
                              ...eyes,
                              confirmPassword: !eyes.confirmPassword,
                            })
                          }
                        ></i>
                      </div>
                      {submitted && getError("confirmPassword").invalid ? (
                        <>
                          {getError("confirmPassword").err.confirmMatch ? (
                            <div className="invalid-feedback d-block">
                              Confirm Password is not matched with New Password
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="flex items-end justify-end">
                    <button
                      type="submit"
                      className="text-[#222] bg-[#ffd6b6]  focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-4 py-2.5 text-center  mb-2 cursor-pointer"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      </Layout>
    </>
    
  );
};

export default ChangePassword;
