import { useNavigate } from "react-router-dom";
import Layout from "../../components/global/layout";
import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import loader from "../../methods/loader";
import { Tooltip } from "antd";  
import Shared from "./Shared";
import { toast } from "react-toastify";

const SubAdminDetail = () => {
  const [data, setData] = useState();
  const history = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, [id]);

  const getDetail = () => {
    loader(true);
    ApiClient.get(`${Shared.detailApi}?id=${id}`).then((res) => {
      if (res?.status == true || res?.code == 200) {
        setData(res.data);
        loader(false);
      } else {
        loader(false);
        toast?.error(res?.message);
      }
    });
  };

  return ( 
    <>
    <Layout>
      <div className="bg-white shadow-box rounded-lg w-full p-4 ">
        <div className="flex items-center mb-8">
          <Tooltip placement="top" title="Back">
            <span
              onClick={() => history(-1)}
              className="!px-4  py-2 cursor-pointer flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all  mr-3"
            >
              <i className="fa fa-angle-left text-lg"></i>
            </span>
          </Tooltip>
          <div>
            <h3 className="text-lg lg:text-2xl font-semibold text-[#111827]">
              Staff  Details
            </h3>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-4">
          <div className="sideclass col-span-12 md:col-span-12">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-6">
                <label className="profileheddingcls"> Full Name</label>
                <div className="profiledetailscls capitalize">
                {data && data.firstName + " " + data?.lastName}
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <label className="profileheddingcls">Email</label>
                <div className="profiledetailscls ">
                  {data?.email || "--"}
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <label className="profileheddingcls">Phone no.</label>
                <div className="profiledetailscls ">
                  {"+" + data?.countryCode} {" "}
                   {data?.mobileNo}
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <label className="profileheddingcls">Role Name</label>
                <div className="profiledetailscls ">
                  { data?.roleName || "N/A"} 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </>
  );
};

export default SubAdminDetail;
