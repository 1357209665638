import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import { Tooltip } from "antd";
import FormControl from "../../components/common/FormControl";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import Shared from "./Shared";
import { toast } from "react-toastify";

const AddEditSubAdmin = () => {
  const { id } = useParams();
  const [roleOptions, setRoleOptions] = useState([]);
  const [form, setform] = useState({
    id: "",
    firstName: "",
    lastName : "",
    email: "",
    roleId: "",
  });
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const inValidEmail = methodModel.emailvalidation(form?.email);
  const [phoneNo, setPhoneNo] = useState("");
  const [dialCode, setDialCode] = useState("");
  const formValidation = [
    { key: "email", required: true, message: "Email is required", email: true },
    { key: "firstName", required: true, message: "First name is required" },
    { key: "lastName", required: true, message: "Last name is required" },
  ];
  useEffect(() => {
    getRolesList();
  }, []);

  const getRolesList = () => {
    ApiClient.get("getAllRoles").then((res) => {
      if (res.success == true || res?.code == 200) {
        const filtered = res?.data.filter((itm) => itm.status == "active" && itm?.name != "user");
        setRoleOptions(
          filtered.map(({ id, name }) => {
            return { id: id, name: name };
          })
        );
      }
    });
  };

  useEffect(() => {
    if (id) {
      subadminDetails();
    }
  }, [id]);

  const subadminDetails = () => {
    loader(true);
    ApiClient.get(`${Shared.detailApi}?id=${id}`).then((res) => {
      if (res.success == true || res?.code == 200) {
        setform({
          ...form,
          firstName: res?.data?.firstName,
          lastName : res?.data?.lastName,
          email: res?.data?.email,
          roleId: res?.data?.roleId,
          
        });
        setPhoneNo(res?.data?.mobileNo || "");
        setDialCode(res?.data?.countryCode || "");
      }
      loader(false);
    });
  };

  const handleChangePhone = (phone, country) => {
    setDialCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    if ( !phoneNo || !form?.roleId) {
      return;
    } 
    let method = "post";
    let url = Shared.addApi;
    let value = {
      ...form,
      countryCode: dialCode,
      mobileNo: phoneNo ? JSON?.parse(phoneNo) : "",
      id: id,
      role: "subAdmin",
    };
    if (id) {
      method = "put";
      url = Shared.editApi;
    } else {
      delete value.id;
    }
    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success == true || res?.code == 200) {
        toast?.success(res?.message);
        history(`/${Shared.url}`);
        loader(false);
      } else {
        loader(false);
        toast?.error(res?.message);
      }
    });
  };
 
  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1">
            <div className="flex items-center mb-8">
              <Tooltip placement="top" title="Back">
                <Link
                  to={`/${Shared.url}`}
                  className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all  mr-3"
                >
                  <i className="fa fa-angle-left text-lg"></i>
                </Link>
              </Tooltip>
              <div>
                <h3 className="text-lg lg:text-2xl font-semibold text-[#111827]">
                  {id ? "Edit" : "Add"} {Shared.addTitle}
                </h3>
                <p class="text-xs lg:text-sm font-normal text-[#75757A]">
                  Here you can see all about your {Shared.addTitle}
                </p>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className=" mb-3">
                <FormControl
                placeholder="First Name"
                  type="text"
                  name="firstName"
                  label="Full Name"
                  value={form.firstName}
                  required
                  onChange={(e) => setform({ ...form, firstName: e })}
                />
                {submitted && !form.firstName && (
                  <div className="invalid-feedback d-block">
                  First name is required
                  </div>
                )}
              </div>
              <div className=" mb-3">
                <FormControl
                placeholder="Last Name"
                  type="text"
                  name="lastName"
                  label="Last name"
                  value={form.lastName}
                  onChange={(e) => setform({ ...form, lastName: e })}
                  required
                />
                {submitted && !form.lastName && (
                  <div className="invalid-feedback d-block">
                  Last  Name is required
                  </div>
                )}
              </div>
              <div className="mobile_number mb-3">
                <FormControl
                placeholder="select Role"
                  type="select"
                  name="roleId"
                  label="Role"
                  value={form?.roleId}
                  options={roleOptions}
                  onChange={(e) => setform({ ...form, roleId: e })}
                  required
                  theme="search"
                />
                {(submitted && !form.roleId) ?(
                  <div className="invalid-feedback d-block">
                    Role is required
                  </div>
                ): ""}
              </div>

              <div className="mobile_number mb-3">
                <label>Mobile No.<span className="star">*</span></label>
                <PhoneInput
                  required
                  placeholder="Mobile Number"
                  className="mobile-input"
                  country={"us"}
                  value={dialCode + phoneNo}
                  enableSearch={true}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                />

                {(submitted && !phoneNo) ?  (
                  <div className="invalid-feedback d-block">
                    Mobile is required
                  </div>
                ) : ""}
              </div>
              <div className=" mb-3">
                <FormControl
                  required
                placeholder="Email"
                  type="text"
                  name="email"
                  label="Email"
                  value={form.email}
                  onChange={(e) => setform({ ...form, email: e })}
                />
                {submitted && !form?.email ? (
                  <div className="invalid-feedback d-block">
                    Email is required
                  </div>
                ) : (
                  form.email &&
                  submitted &&
                  !inValidEmail && (
                    <div className="invalid-feedback d-block">
                      Please enter valid email
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="text-right">
              <button
                type="submit"
                className="text-[#222222d6] bg-[#ffd6b6] bg-[#ffd6b6] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddEditSubAdmin;
