import React, { useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import styles from "./index.module.css";
import { NavLink, useLocation } from "react-router-dom";
import { RiHome6Line } from "react-icons/ri";
import { TiArrowSortedDown } from "react-icons/ti";
import { TbCategoryPlus } from "react-icons/tb";
import { GrUserSettings } from "react-icons/gr";
import { MdContentPaste, MdOutlineGroups } from "react-icons/md";
import { FaQuestion } from "react-icons/fa";
import { useSelector } from "react-redux";
import { MdOutlineFoodBank } from "react-icons/md";
import { GoGoal } from "react-icons/go";
import { MdOutlineManageHistory } from "react-icons/md";
import { LuUser2 } from "react-icons/lu";

const Html = ({ tabclass,isOpen,isAllow  }) => { 
  const location = useLocation(); 
  const user = useSelector((state) => state.user);

  const menus = [
    {
      name: "Main Menu",
    },
    {
      name: "Dashboard",
      icon: <RiHome6Line className="text-[#222222d6] shrink-0 text-lg " />,
      url: "/dashboard",
      key: "readDashboard",
    },
    {
      name: "Staff Management",
      icon: <TbCategoryPlus className="text-[#222222d6] shrink-0 text-lg " />,
      url: "/subadmin",
      key: "subadmin",
    },
    {
      name: "Roles",
      icon: <GrUserSettings className="text-[#222222d6] shrink-0 text-lg " />,
      url: "/roles",
      key: "readRoles",
    },
    {
      name: "Users",
      icon: <MdOutlineGroups className="text-[#222222d6] shrink-0 text-lg " />,
      url: "/users",
      key: "readUsers",
    },

    {
      name: "Categories",
      icon: <TbCategoryPlus className="text-[#222222d6] shrink-0 text-lg " />,
      url: "/category",
      key: "readCategories",
    },
    {
      name: "Video urls",
      icon: <TbCategoryPlus className="text-[#222222d6] shrink-0 text-lg " />,
      url: "/video",
      key: "readvideo",
    },
    // {
    //   name: "Food List",
    //   icon: <TbCategoryPlus className="text-[#222222d6] shrink-0 text-lg " />,
    //   url: "/foodlist",
    //   key: "readFoodlist",
    // },
    {
      name: "Theme Management",
      icon: <MdOutlineManageHistory className="text-[#222222d6] shrink-0 text-lg " />,
      url: "/theme",
      key: "",
    },

    {
      name: "Diet Type Management",
      icon: <MdOutlineFoodBank className="text-[#222222d6] shrink-0 text-lg " />,
      url: "/diettype",
      key: "",
    },
    {
      name: "Diet Goals Management",
      icon: <GoGoal className="text-[#222222d6] shrink-0 text-lg " />,
      url: "/dietgoals",
      key: "",
    },

    {
      name: "Subscription Plans",
      icon: <MdContentPaste className="text-[#222222d6] shrink-0 text-lg " />,
      key: "",
      // tab: "subscription-plan",
      url: "/plans",
      // menu: [
      //   {
      //     name: "Plans",
      //     icon: <FaQuestion className="text-[#222222d6] shrink-0 text-lg " />,
      //     url: "/plans",
      //     key: "",
      //   },
      //   // {
      //   //   name: "Features",
      //   //   icon: <MdContentPaste className="text-[#222222d6] shrink-0 text-lg " />,
      //   //   url: "/features",
      //   //   key: "",
      //   // },
      // ],
    },
    // {
    //   name: "Products",
    //   icon: <BiCartAdd className="text-[#fff] shrink-0 text-lg" />,
    //   url: "/product",
    //   key: "",
    // },
    // {
    //   name: "Blogs",
    //   icon: <TfiLayoutMediaCenterAlt className="text-[#fff] shrink-0 text-lg" />,
    //   url: "/blog",
    //   key: "",
    // },
    // {
    //   name: "Content Management",
    //   icon: <MdContentPaste className="text-[#fff] shrink-0 text-lg" />,
    //   url: "/content-management",
    //   key: "",
    //   tab: "content-management",
    //   menu: [
    //     {
    //       name: "FAQ",
    //       icon: <FaQuestion className="text-[#fff] shrink-0 text-lg" />,
    //       url: "/faq",
    //       key: "",
    //     },
    //     {
    //       name: "Content",
    //       icon: <MdContentPaste className="text-[#fff] shrink-0 text-lg" />,
    //       url: "/content",
    //       key: "",
    //     },
    //     {
    //       name: "Newsletter",
    //       icon: <PiNewspaper className="text-[#fff] shrink-0 text-lg" />,
    //       url: "/newsletter",
    //       key: "",
    //     },
    //     {
    //       name: "Subscribers",
    //       icon: (
    //         <MdOutlineMarkEmailRead className="text-[#fff] shrink-0 text-lg" />
    //       ),
    //       url: "/subscribers",
    //       key: "",
    //     },
    //   ],
    // },
  ];

  return (
    <>
      <div
        className={`px-[8px] ${isOpen && styles.sm_sidebar}`}
        component="siderbar"
      >
        <ul className="space-y-2 px-2">
          {menus.map((itm) => {
            return (
              <>
                {itm.icon ? (
                  <>
                    <li>
                      {itm.menu ? (
                        <>
                          <Disclosure as="div" defaultOpen={tabclass(itm.tab)}>
                            {({ open }) => (
                              <>
                                <tooltip placement="right" title={itm.name}>
                                  <Disclosure.Button className="w-full p-2.5 rounded-md flex items-center justify-between  bg-[#fff] text-sm font-normal text-[#222222d6]   gap-[12px] hover:bg-[#ffd6b6] transition-all duration-300">
                                    <span className="text-sm font-normal  flex items-center gap-[12px] crm">
                                      {itm.icon}
                                      <span className=" text-inherit leading-none sidebar_text">
                                        {itm.name}
                                      </span>
                                    </span>
                                    <TiArrowSortedDown
                                      className={`${
                                        open ? "" : "-rotate-90 transform"
                                      } h-4 w-4 transition-all duration-500  text-[#222222d6]`}
                                    />
                                  </Disclosure.Button>
                                </tooltip>
                                <Transition
                                  enter="transition duration-300 ease-in-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-300 opacity-300"
                                  leave="transition duration-300 ease-in-out"
                                  leaveFrom="transform scale-300 opacity-300"
                                  leaveTo="transform scale-95 opacity-0"
                                >
                                  <Disclosure.Panel className="pl-[30px] mt-[4px] ">
                                    <ul className="space-y-2">
                                      {itm.menu?.map((sitm) => { 
                                        return (
                                          <>
                                            {isAllow(sitm.key) ? (
                                            <li>
                                              {" "}
                                              <NavLink
                                                className={(isActive) =>
                                                  "p-2.5 rounded-md block text-sm font-normal text-[#222222d6]  cursor-pointer  hover:bg-[#ffd6b6] !no-underline transition-all " +
                                                  (location?.pathname ===
                                                    sitm.url &&
                                                    " text-[#222222d6] !font-medium")
                                                }
                                                to={sitm.url}
                                              >
                                                <span
                                                  className="text-inherit leading-none sidebar_text"
                                                  title={sitm.name}
                                                >
                                                  {sitm.name}
                                                </span>
                                              </NavLink>
                                            </li>
                                           ) : null}
                                          </>
                                        );
                                      })}
                                    </ul>
                                  </Disclosure.Panel>
                                </Transition>
                              </>
                            )}
                          </Disclosure>
                        </>
                      ) : (
                        <>
                          {isAllow(itm.key) ? (
                          <>
                            <tooltip
                              placement="top"
                              color="#ffd6b6"
                              title={itm.name}
                            >
                              <NavLink
                                to={itm.url}
                                className={(isActive) =>
                                  "p-2.5 rounded-md flex items-center gap-[12px]   text-sm font-normal text-[#222222d6] hover:bg-[#ffd6b6] !no-underline transition-all group " +
                                  (location?.pathname === itm.url &&
                                    " !text-[#222222d6] !bg-[#ffd6b6] !font-medium text-[#222222d6] highlighted")
                                }
                              >
                                <div className={` ${(location?.pathname === itm.url &&
                                    " !text-[#222222d6]  !font-medium text-[#222222d6] highlighted")}
                                }`}>
                                {itm.icon}
                                </div>
                                <span className="text-inherit leading-none sidebar_text  ">
                                  {itm.name}
                                </span>
                              </NavLink>
                            </tooltip>
                          </>
                         ) : ( 
                          <></>
                         )} 
                        </>
                      )}
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <h6
                        className={`${
                          isOpen
                            ? "py-[12px] text-center"
                            : "p-[12px] text-center text-md"
                        } text-xs font-medium text-[#7E8B99] mt-[12px] `}
                      >
                        <span className=" sidebar_text text-center">
                          {" "}
                          {itm.name}{" "}
                        </span>
                      </h6>
                    </li>
                  </>
                )}
              </>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Html;
