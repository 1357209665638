import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"; 
import Login from "./pages/auth/login";
import NotFoundPage from "./pages/NotFoundPage";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "./scss/main.scss";
import Profile from "./pages/auth/profile"; 
import Forgotpassword from "./pages/auth/forgotpassword";
import Resetpassword from "./pages/auth/resetpassword";
import Roles from "./pages/roles";
import AddEditRole from "./pages/roles/AddEdit";
import Dashboard from "./pages/dashboard"; 
import configureStoreProd from "./redux/configureStore.prod";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify"; 
import Category from "./pages/categories";
import AddEditCategory from "./pages/categories/AddEdit";
import ViewCategory from "./pages/categories/View";
import FAQ from "./pages/faq";
import AddEditFAQ from "./pages/faq/AddEdit";
import ViewFAQ from "./pages/faq/View";
import Content from "./pages/content";
import AddEditContent from "./pages/content/AddEdit";
import ViewContent from "./pages/content/View";  
import Users from "./pages/users";
import UserDetail from "./pages/users/View";
import AddEditUser from "./pages/users/AddEdit"; 
import Plans from './pages/plans';
import AddEditPlan from './pages/plans/AddEdit';
import Features from './pages/features';
import AddEditFeature from './pages/features/AddEdit';
import ThemeManagement from "./pages/theme";
import AddEditTheme from "./pages/theme/AddEdit";
import ThemeDetail from "./pages/theme/View";
import SubAdmin from "./pages/subadmin";
import AddEditSubAdmin from "./pages/subadmin/AddEdit";
import SubAdminDetail from "./pages/subadmin/View";
import DietType from "./pages/diettype";
import AddEditDietType from "./pages/diettype/AddEdit";
import DietTypeDetails from "./pages/diettype/View";
import DietGoals from "./pages/dietgoals";
import AddEditDietGoals from "./pages/dietgoals/AddEdit";
import DietGoalsDetails from "./pages/dietgoals/View";
import EditProfile from "./pages/auth/profile/editprofile";
import ChangePassword from "./pages/auth/changepassword";
import FeaturesDetails from "./pages/features/detail";
import PlanDetail from "./pages/plans/detail";
import RoleDetails from "./pages/roles/View";
import FoodList from "./pages/foodlist";
import Videos from "./pages/videos";
import AddEditVideos from "./pages/videos/AddEdit";
import VideosDetails from "./pages/videos/View";

const { persistor, store } = configureStoreProd();

function App() {
  const routes = [ 
    { url: "/login", element: <Login /> }, 
    { url: "/", element: <Navigate to="/login" /> },
    { url: "/dashboard", element: <Dashboard /> },
    { url: "*", element: <NotFoundPage /> },
    { url: "/profile", element: <Profile /> }, 
    { url: "/profile/edit", element: <EditProfile/> }, 
    { url: "/forgotpassword", element: <Forgotpassword /> },
    { url: "/resetpassword", element: <Resetpassword /> },
    { url: "/changepassword", element: <ChangePassword /> },
    { url: "/roles", element: <Roles /> },
    { url: "/roles/add", element: <AddEditRole /> },
    { url: "/roles/edit/:id", element: <AddEditRole /> }, 
    { url: "/roles/detail/:id", element: <RoleDetails /> }, 
    { url: "/plans", element: <Plans /> },
    { url: "/plans/add", element: <AddEditPlan /> },
    { url: "/plans/edit/:id", element: <AddEditPlan /> },
    { url: "/plans/detail/:id", element: <PlanDetail/> },
    { url: "/features", element: <Features /> },
    { url: "/features/add", element: <AddEditFeature /> },
    { url: "/features/edit/:id", element: <AddEditFeature /> },
    { url: "/features/detail/:id", element: <FeaturesDetails/> },
    { url: "/category", element: <Category /> },
    { url: "/category/add", element: <AddEditCategory /> },
    { url: "/category/detail/:id", element: <ViewCategory /> },
    { url: "/category/edit/:id", element: <AddEditCategory /> },
    { url: "/faq", element: <FAQ /> },
    { url: "/faq/add", element: <AddEditFAQ /> },
    { url: "/faq/detail/:id", element: <ViewFAQ /> },
    { url: "/faq/edit/:id", element: <AddEditFAQ /> },
    { url: "/content", element: <Content /> },
    { url: "/content/detail/:slug", element: <ViewContent /> },
    { url: "/content/add", element: <AddEditContent /> },
    { url: "/content/edit/:slug", element: <AddEditContent /> },    
    { url: "/users", element: <Users /> },
    { url: "/users/edit/:id", element: <AddEditUser /> },
    { url: "/users/add", element: <AddEditUser /> },
    { url: "/users/detail/:id", element: <UserDetail /> }, 
    { url: "/theme", element: <ThemeManagement/> },
    { url: "/theme/edit/:id", element: <AddEditTheme /> },
    { url: "/theme/add", element: <AddEditTheme /> },
    { url: "/theme/detail/:id", element: <ThemeDetail/> }, 
    { url: "/subadmin", element: <SubAdmin/> },
    { url: "/subadmin/edit/:id", element: <AddEditSubAdmin /> },
    { url: "/subadmin/add", element: <AddEditSubAdmin /> },
    { url: "/subadmin/detail/:id", element: <SubAdminDetail/> }, 
    { url: "/diettype", element: <DietType/> },
    { url: "/diettype/edit/:id", element: <AddEditDietType /> },
    { url: "/diettype/add", element: <AddEditDietType /> },
    { url: "/diettype/detail/:id", element: <DietTypeDetails/> }, 
    { url: "/dietgoals", element: <DietGoals/> },
    { url: "/dietgoals/edit/:id", element: <AddEditDietGoals /> },
    { url: "/dietgoals/add", element: <AddEditDietGoals /> },
    { url: "/dietgoals/detail/:id", element: <DietGoalsDetails/> }, 
    { url: "/foodlist", element: <FoodList/> },
    { url: "/video", element: <Videos /> },
    { url: "/video/add", element: <AddEditVideos /> },
    { url: "/video/edit/:id", element: <AddEditVideos /> },
    { url: "/video/detail/:id", element: <VideosDetails/> }, 
  ];

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={"loading ..."} persistor={persistor}>
          <Router>
            <Routes>
              {routes.map((itm) => {
                return <Route path={itm.url} element={itm.element} />;
              })}
            </Routes>
          </Router>
        </PersistGate>
      </Provider>
      <div id="loader" className="loaderDiv d-none">
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>
      <ToastContainer autoClose={2000}  position="top-right" />
    </>
  );
}

export default App;
