import React, { useState, useEffect } from "react";
import ApiClient from "../../../../methods/api/apiClient";
import loader from "../../../../methods/loader";
import "./style.scss";
import Html from "./Html";
import { useNavigate } from "react-router-dom";
import formModel from "../../../../models/form.model";
import { useDispatch, useSelector } from "react-redux";
import { login_success } from "../../../../redux/actions/user";
import { toast } from "react-toastify";

const EditProfile = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const [phoneNo, setPhoneNo] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [images, setImages]: any = useState({ uploadDocument: "" });
  const [form, setForm]: any = useState({
    id: "",
    email: "",
    fullName: "",
  });

  useEffect(() => {
    if (user.loggedIn) {
      getProfile();
    }
  }, []);

  const getProfile = () => {
    loader(true);
    ApiClient.get(`profile`).then((res) => {
      if (res.success == true || res?.code == 200) {
        setForm({
          ...form,
          fullName: res?.data?.fullName,
          email: res?.data?.email,
        });
        setPhoneNo(res?.data?.mobileNo || "");
        setDialCode(res?.data?.countryCode || "");
        setImages({ ...images, uploadDocument: res?.data?.image });
        loader(false);
      } else {
        loader(false);
        toast?.error(res?.message);
      }
    });
  };
  const imageResult = (e: any, key: any) => {
    if (e.event == "remove") {
      setImages({ uploadDocument: "" });
    } else {
      setImages({ uploadDocument: e.value });
    }
  };
  const handleChangePhone = (phone: any, country: any) => {
    setDialCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
  };
  const goBack = () => {
    history("/profile");
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = formModel.getFormError("profileForm");
    if (invalid) return;

    let value = {
      ...form,
      id: user.id,
      countryCode: dialCode,
      mobileNo: phoneNo ? JSON?.parse(phoneNo) : "",
      image: images?.uploadDocument,
    };
    loader(true);
    ApiClient.put("editAdminProfile", value).then((res) => {
      if (res.success == true || res?.code == 200) {
        let uUser = { ...user, ...value };
        dispatch(login_success(uUser));
        history("/profile");
        toast?.success(res?.message);
        loader(false);
      } else {
        toast?.error(res?.message);
        loader(false);
      }
    });
  };

  return (
    <>
      <Html
        handleSubmit={handleSubmit}
        setForm={setForm}
        form={form}
        submitted={submitted}
        imageResult={imageResult}
        images={images}
        dialCode={dialCode}
        phoneNo={phoneNo}
        handleChangePhone={handleChangePhone}
        goBack={goBack}
      />
    </>
  );
};

export default EditProfile;
