const Shared = {
    check: "Customers",
    title: "Theme Management",
    addTitle: "Theme",
    url: "theme",
    addApi: "user/add",
    editApi: "user/profile",
    detailApi: "user/profile",
    listApi: "user/frontend/lisitng",
    statusApi: "user/status/change",
    deleteApi: "user/delete",
  };
  
  export default Shared;
  