import { Link } from "react-router-dom";

const AuthLayout = ({ children }: any) => {
  return (
    <>
      <div className="grid items-center grid-cols-12 overflow-hidden ">
        <div className="col-span-12 md:col-span-6  lg:col-span-5 xl:col-span-5  h-screen">
          <div className="relative w-full h-full">
            <div className="flex flex-col justify-center  px-3 xs:px-4     relative z-20 h-full">
              <Link to="/" className="h-[10%]">
                <img
                  src="/assets/img/logos.png"
                  className="w-[35 0px] pt-5 pl-10"
                  alt="logo"
                />
              </Link>
              <div className="flex items-center justify-center   w-full  lg:pl-10 md:pl-5 relative z-20 h-[90%]">
                {children}
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-span-12 md:col-span-6 lg:col-span-7 xl:col-span-7 w-100  h-full
       "
        >
          <div className="w-100  d-block h-full">
            <img
              src="/assets/img/login.png"
              alt="logo"
              className="w-100  h-full  lg:object-cover  md:object-contain md:max-w-[400px] lg:max-w-[682px] xl:max-w-[870px] ml-auto	"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
