import React from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import { Link } from "react-router-dom";
import environment from "../../environment";
import { Tooltip } from "antd";
import { HiOutlineArrowDown } from "react-icons/hi";
import { FiPlus } from "react-icons/fi";
import SelectDropdown from "../../components/common/SelectDropdown";
import statusModel from "../../models/status.model";
import { LiaEdit, LiaTrashAlt } from "react-icons/lia";
const Html = ({
  clear,
  view,
  edit,
  ChangeStatus,
  statusChange,
  pageChange,
  deleteItem,
  filters,
  data,
  isAllow,
  total = { total },
}) => {
  return (
    <Layout>
      <div className="flex justify-between items-center mb-6">
        <div>
          <h3 className="text-2xl font-semibold text-[#111827]"> Roles</h3>
          <p className="text-sm font-normal text-[#75757A]">
            Here you can see all about your Roles
          </p>
        </div>
        <div className="flex items-center gap-2">
          {isAllow("addRoles") ? (
            <Link
              className="bg-primary leading-10 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-[#222222d6] rounded-lg gap-2"
              to="/roles/add"
            >
              <FiPlus className="text-xl text-[#222222d6]" /> Add Role
            </Link>
          ) : (
            <></>
          )}

          <SelectDropdown
            id="statusDropdown"
            displayValue="name"
            placeholder="All Status"
            intialValue={filters.status}
            result={(e) => {
              ChangeStatus(e.value);
            }}
            options={statusModel.list}
          />
          {filters.status ? (
            <>
              <button
                className="bg-primary leading-10 h-10 inline-block shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg"
                onClick={() => clear()}
              >
                Reset
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <>
        <div className="shadow-box w-full bg-white rounded-lg">
          <div className="scrollbar w-full overflow-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr className="">
                  <th scope="col" className="px-6 py-4 bg-[#ffd6b93b]">
                    Role Name{" "}
                    <span className="ml-1">
                      <HiOutlineArrowDown className="shrink-0 inline text-sm" />
                    </span>
                  </th>
                  <th scope="col" className="px-6 py-4 bg-[#ffd6b93b]">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-4 bg-[#ffd6b93b]">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((itm, i) => {
                    if (
                      itm.id != environment.adminRoleId &&
                      itm.id != environment.userRoleId
                    )
                      return (
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                          <td 
                            onClick={() => {
                              view(itm?.id);
                            }}
                            className="px-6 py-4"
                          >
                            {itm.name}
                          </td>
                          <td className="px-6 py-4">
                            <div
                              className="w-32"
                              onClick={() => statusChange(itm)}
                            >
                              <span
                                className={`cursor-pointer text-sm !px-3 h-[30px] w-[100px] flex items-center justify-center border border-[#EBEBEB] text-[#3C3E49A3] !rounded capitalize 
                          ${
                            itm.status == "deactive"
                              ? " bg-gray-200 text-black"
                              : "bg-[#130264] text-white"
                          }`}
                              >
                                {itm.status == "deactive"
                                  ? "inactive"
                                  : "active"}
                              </span>
                            </div>
                          </td>

                          {/* dropdown */}
                          <td className="px-6 py-4">
                            <div className="flex items-center justify-start gap-1.5">
                              {isAllow(`editRoles`) ? (
                                <Tooltip placement="top" title="Edit">
                                  <a
                                    className="border cursor-pointer  hover:opacity-70 rounded-lg bg-[#ffd6b614] w-10 h-10 !text-primary flex items-center justify-center text-lg"
                                    onClick={(e) => edit(itm.id)}
                                  >
                                    <LiaEdit />
                                  </a>
                                </Tooltip>
                              ) : (
                                ""
                              )}

                              {/* {isAllow("deleteRoles") ? (
                                <>
                                  <Tooltip placement="top" title="Delete">
                                    <span
                                      className="border cursor-pointer  hover:opacity-70 rounded-lg bg-[#ffd6b614] w-10 h-10 !text-primary flex items-center justify-center text-lg "
                                      onClick={() => deleteItem(itm.id)}
                                    >
                                      <LiaTrashAlt />
                                    </span>
                                  </Tooltip>
                                </>
                              ) : (
                                <></>
                              )} */}
                            </div>
                          </td>

                          {/* end */}
                        </tr>
                      );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </>
      {total > filters.count ? (
        <div className="paginationWrapper  mt-15 ">
          <Pagination
            currentPage={filters.page}
            totalSize={total}
            sizePerPage={filters.count}
            changeCurrentPage={pageChange}
          />
        </div>
       ) : (
        <></>
      )} 
    </Layout>
  );
};

export default Html;
