import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import './style.scss'; 
import { toast } from 'react-toastify';

const FeaturesDetails = (p) => {
    const history = useNavigate() 
    const { id } = useParams()
    const [data, setData] = useState()

    useEffect(() => {
        if(id){
            getDetail()
        }    
    }, [id])
    const getDetail = () => {
        loader(true)
        ApiClient.get(`feature?id=${id}`).then(res => {
            if (res.success == true || res?.code == 200) {
                setData(res.data)
                loader(false)
            }
             else {
                loader(false)
                toast?.error(res?.message)
             }
           
        })
    };
    const back = () => {
        history(-1)
    }
    return (
        <Layout>
            <div className="text-right">
                <div>
                    <a to="/features" onClick={back}>  <i className="fa fa-arrow-left mr-4 mb-3 " title='Back' aria-hidden="true"></i></a>
                </div>
            </div>
            <div className="row">
                <div className="sideclass col-md-12">
                    <h3 className="Profilehedding mt-3 ">
                    Feature Detail
                    </h3>

                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Name</label>
                            <div className='profiledetailscls'>{data && data.name}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Category Name</label>
                            <div className='profiledetailscls'>{data && data.category_name}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Status</label>
                            <div className='profiledetailscls'>{data && data.status}</div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="profileheddingcls">Tooltip</label>
                            <div className='profiledetailscls'>{data && data.tooltip}</div>
                        </div>
                      
                    </div>
                </div>
            </div>
        </Layout >

    );
};

export default FeaturesDetails;
