import React from "react";
import "./style.scss";
import PhoneInput from "react-phone-input-2";
import ImageUpload from "../../../../components/common/ImageUpload";
import FormControl from "../../../../components/common/FormControl";
import Layout from "../../../../components/global/layout";

const Html = ({
  handleSubmit,
  setForm,
  form, 
  imageResult,
  images, 
  submitted,
  phoneNo,
  dialCode,
  handleChangePhone,
  goBack,
}) => {
  return (
    <>
      <Layout>
        <div className="wrapper_section">
          <div className="flex items-center  justify-between">
            <h3 className="text-2xl font-semibold text-[#111827]">
              Edit Profile
            </h3>
          </div>

          <form name="profileForm" className="" onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 mb-4 gap-4 shadow p-4 mt-6 gap-4  bg-white">
              <div className="col-span-12 md:col-span-6">
                <FormControl
                  type="text"
                  label="Full Name"
                  value={form.fullName}
                  onChange={(e) => setForm({ ...form, fullName: e })}
                  required
                />
              </div>

              <div className="col-span-12 md:col-span-6 ">
                <label>Mobile number </label>
                <PhoneInput
                  placeholder="Mobile Number"
                  className="mobile-input"
                  country={"us"}
                  value={dialCode + phoneNo}
                  enableSearch={true}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                />

                {submitted && !phoneNo && !dialCode && (
                  <div className="invalid-feedback d-block">
                    Mobile is required
                  </div>
                )}
              </div>

              <div className="col-span-12 md:col-span-6">
                <label>Email</label>
                <input
                  type="email"
                  className="relative shadow-box bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                  value={form.email}
                  autoComplete="false"
                  onChange={(e) => {
                    setForm({ ...form, email: e.target.value });
                  }}
                  required
                  disabled
                />
              </div>

              <div className="mb-3">
                <label className="lablefontcls">Image</label>
                <br></br>
                <ImageUpload
                  model="category"
                  result={(e) => imageResult(e, "uploadDocument")}
                  value={images.uploadDocument}
                  label="Upload Image"
                  type={images?.uploadDocument}
                  url="upload/image?modelName="
                  multiple={false}
                />
              </div>
            </div>
            <div className="text-right mt-3">
              <button
                type="button"
                onClick={() => {
                  goBack();
                }}
                className="text-white bg-gray-400 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-3"
              >
                Cancel
              </button>
              <button className="text-white bg-[#ffd6b6] bg-[#ffd6b6] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                Save
              </button>
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
};

export default Html;
