const types = [
    { id: "features", name: "Features" },
    { id: "diet", name: "diet" }, 
  ];
  
  const Shared = {
    check: "Categories",
    title: "Diet Goals Management",
    addTitle: "Diet Goal",
    url: "dietgoals",
    types: [...types],
    addApi: "category/add",
    editApi: "category/update",
    detailApi: "category/detail",
    listApi: "category/listing",
    statusApi: "category/update",
    deleteApi: "category/delete",
  };
  
  export default Shared;
  