import React from "react";
import methodModel from "../../../methods/methods";
import { FiPlus } from "react-icons/fi";
const Html = ({
  inputElement,
  uploadImage,
  img,
  remove,
  loader,
  model,
  multiple,
  required,
  submitted,
  err,
  label = "",
  type = "image",
}) => {
  return (
    <>
     <label
      className={`flex items-center justify-center cursor-pointer text-black-800 bg-[#fff]  focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5  border-2 border-dashed border-gray-200 w-full ${
        img && !multiple ? "d-none" : ""
      }`}
      style={{ gap: '8px' }}
    >
    <FiPlus/>
      <input
        type="file"
        className="d-none"
        ref={inputElement}
         accept="image/*"

        multiple={!!multiple}
        disabled={loader}
        onChange={(e) => {
          uploadImage(e);
        }}
      />
      {label || "Upload Image"}
    </label>

      {loader ? (
        <div className="text-success text-center mt-2">
          Uploading... <i className="fa fa-spinner fa-spin"></i>
        </div>
      ) : (
        <></>
      )}

      {multiple ? (
        <>
          <div className="imagesRow">
            {img &&
              img.map((itm, i) => {
                return (
                  <div className="imagethumbWrapper">
                    <img
                      src={methodModel.noImg(itm, model)}
                      className="thumbnail"
                    />
                    <i
                      className="fa fa-times"
                      title="Remove"
                      onClick={(e) => remove(i)}
                    ></i>
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <>
          {img ? (
            <div className="imagethumbWrapper">
              {type && type == "video" && (
                <video
                  src={methodModel.noImg(img, model)}
                  controls
                  width={150}
                ></video>
              )}
              {type != "video" && (
                type == "file" ? (
                  <img
                    src={"/assets/img/pdf.png.webp"}
                    className="thumbnail"
                  />) :
                <img
                  src={methodModel.noImg(img, model)}
                  className="thumbnail"
                />
              )}
            
              <i
                className="fa fa-times"
                title="Remove"
                onClick={(e) => remove()}
              ></i>
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      {required &&submitted&& type === "image" && !img ? (
        <div className="text-danger">{err ? err : "Image is Required"}</div>
      ) : (
        <></>
      )}
       {required &&submitted&& type === "video" && !img ? (
        <div className="text-danger">{err ? err : "Vedio is Required"}</div>
      ) : (
        <></>
      )}
    </>
  );
};
export default Html;
