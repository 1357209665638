import React, { useEffect } from 'react';
import Layout from '../../../components/global/layout';
import { useState } from 'react';
import ApiClient from '../../../methods/api/apiClient';
import { useParams, useNavigate } from 'react-router';
import loader from '../../../methods/loader'; 

const PlanDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setdata] = useState({});

  const getdetails = () => {
    loader(true);
    ApiClient.get(`subscription-plan?id=${id}`).then((res) => {
      if (res.success) {
        setdata(res.data);
        loader(false);
      }
      loader(false);
    });
  };
  useEffect(() => {
    getdetails();
  }, []);

  const back = () => {
    navigate('/plans');
  };

  return (
    <>
      <Layout>
        <div className="pprofile1 shadow-lg">
          <div className="flex items-center mb-8">
            <button
              type="button"
              class="!px-4 bg-[#fff] py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border  transition-all mr-3"
              to="/users"
              onClick={back}
            >
              <i class="fa fa-angle-left text-lg"></i>
            </button>
            <div>
              <h3 className="text-2xl font-semibold text-[#111827]">
             Plan Details
              </h3>
              <p className="text-sm font-normal text-[#525252]">
                Here you can see all about your Plans
              </p>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 md:col-span-6">
              <label className="profileheddingcls">Name</label>
              <div className="profiledetailscls bg-[#f5f5f5] px-[10px] py-[8px] min-h-[38px] rounded-md capitalize">
                {data && data?.name}
              </div>
            </div>
            <div className="col-span-12 md:col-span-12">
            
            </div>

            {data &&
              data?.pricing?.map((itm) => {
                return (
                  <>
                    <div className="col-span-12 md:col-span-6">
                      <label className="profileheddingcls">{itm?.label}</label>
                      <div className="pprofiledetailscls bg-[#f5f5f5] px-[10px] py-[8px] min-h-[38px] rounded-md">
                        {data && itm?.interval_count} {data && itm?.interval_count > 1 ? "Months" : "Month"}
                      </div>
                    </div>

                    <div className="col-span-12 md:col-span-6">
                      <label className="profileheddingcls">Unit Amount</label>
                      <div className="pprofiledetailscls bg-[#f5f5f5] px-[10px] py-[8px] min-h-[38px] rounded-md">
                        ${data &&  itm?.unit_amount}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          <div className="col-span-12 md:col-span-6">
              <label className="profileheddingcls">Description</label>
              <div className="profiledetailscls bg-[#f5f5f5] px-[10px] py-[8px] min-h-[38px] rounded-md capitalize">
                <p dangerouslySetInnerHTML={{__html:data?.description}}></p>
              </div>
            </div>
        </div>
      </Layout>
    </>
  );
};
export default PlanDetail;
