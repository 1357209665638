import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import Html from "./Html";
import { useDispatch, useSelector } from "react-redux"; 
import { logout } from "../../../redux/actions/user";
import { toast } from "react-toastify";

const Header = ({ setIsOpen, isOpen }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const Logout = () => {
    dispatch(logout());
    localStorage.removeItem("admindetails");
    localStorage.removeItem("token"); 
    localStorage?.removeItem("route")
    localStorage?.removeItem("permissions")
    toast?.success("Logout Successfully")
    history("/login");
  }; 
  return (
    <Html 
      user={user} 
      Logout={Logout} 
    />
  );
};

export default Header;
