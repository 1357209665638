import React, { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { planType } from "../../models/type.model";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import SelectDropdown from "../../components/common/SelectDropdown";
import requiredModel from "../../models/required.model";
import { Tooltip } from "antd";
import { toast } from "react-toastify";
import FormControl from "../../components/common/FormControl";

const AddEditPlan = () => {
  const [features, setFeatures] = useState([]);

  const defaultvalue = () => {
    let keys = { ...planType };
    Object.keys(planType).map((itm) => {
      keys[itm] = "";
    });
    keys.status = "active";
    return keys;
  };
  const { id } = useParams();
  const [form, setform] = useState(planType);
  const history = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [SelectedFeature, setSelectedFeatures] = useState([]);
  const [OneMonth, setOneMonth] = useState({
    label: "Monthly",
    interval_count: 1,
    interval_time: "month",
    unit_amount: "",
    is_most_popular: false,
  });
  const [ThreeMonth, setThreeMonth] = useState({
    label: "Quarterly",
    interval_count: 3,
    interval_time: "month",
    unit_amount: "",
    is_most_popular: false,
  });
  // const [SixMonth, setSixMonth] = useState({
  //   label: "6 Months",
  //   interval_count: 6,
  //   interval_time: "month",
  //   unit_amount: "",
  //   is_most_popular: false,
  // });
  const [TwelveMonth, setTwelveMonth] = useState({
    label: "Yearly",
    interval_count: 12,
    interval_time: "month",
    unit_amount: "",
    is_most_popular: false,
  });
  const formValidation = [{ key: "name", required: true }];

  // const trailPeriodDays = [
  //   { id: 1, name: "1" },
  //   { id: 2, name: "2" },
  //   { id: 3, name: "3" },
  //   { id: 4, name: "4" },
  //   { id: 5, name: "5" },
  //   { id: 6, name: "6" },
  //   { id: 7, name: "7" },
  //   { id: 8, name: "8" },
  //   { id: 9, name: "9" },
  //   { id: 10, name: "10" },
  //   { id: 11, name: "11" },
  //   { id: 12, name: "12" },
  //   { id: 13, name: "13" },
  //   { id: 14, name: "14" },
  //   { id: 15, name: "15" },
  //   { id: 16, name: "16" },
  //   { id: 17, name: "17" },
  //   { id: 18, name: "18" },
  //   { id: 19, name: "19" },
  //   { id: 20, name: "20" },
  //   { id: 21, name: "21" },
  //   { id: 22, name: "22" },
  //   { id: 23, name: "23" },
  //   { id: 24, name: "24" },
  //   { id: 25, name: "25" },
  //   { id: 26, name: "26" },
  //   { id: 27, name: "27" },
  //   { id: 28, name: "28" },
  //   { id: 29, name: "29" },
  //   { id: 30, name: "30" },
  // ];

  useEffect(() => {
    getFeatures();
  }, []);

  const getFeatures = () => {
    ApiClient.get("features", { page: 1, count: 10, status: "active" }).then(
      (res) => {
        if (res.success == true || res?.code == 200) {
          setFeatures(res?.data);
        }
      }
    );
  };
  useEffect(() => {
    if (id) {
      getOnePlan();
    } else {
      setform(defaultvalue());
    }
  }, [id]);

  const getOnePlan = () => {
    loader(true);
    ApiClient.get(`subscription-plan?id=${id}`).then((res) => {
      if (res.success == true || res?.code == 200) {
        setform({
          ...form,
          name: res?.data?.name,
          descriptionMonthly: res?.data?.descriptionMonthly || "",
          descriptionQuarterly: res?.data?.descriptionMonthly || "",
          descriptionYearly: res?.data?.descriptionYearly || ""


          // recomended: res?.data?.recomended,
          // trial_period_days: res?.data?.trial_period_days,
        });
        setSelectedFeatures(res?.data?.features);
        setOneMonth(res?.data?.pricing ? res?.data?.pricing[0] : {});
        setThreeMonth(res?.data?.pricing ? res?.data?.pricing[1] : {});
        // setSixMonth(res?.data?.pricing ? res?.data?.pricing?.[2] : {});
        setTwelveMonth(res?.data?.pricing ? res?.data?.pricing[2] : {});
        loader(false);
      } else {
        toast?.error(res?.message);
        loader(false);
      }
    });
  };
  // const isFeatureSelected = (featureName) => {
  //   return SelectedFeature.some((itm) => itm?.id == featureName);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    let invalid = methodModel.getFormError(formValidation, form);
    if (invalid) return;
    else if (
      !OneMonth?.unit_amount ||
      !ThreeMonth?.unit_amount ||
      !TwelveMonth?.unit_amount
    ) {
      return;
    }
    let method = "post";
    let url = "subscription-plan";
    let value;
    value = {
      ...form,
      pricing: [OneMonth, ThreeMonth, TwelveMonth],
    };

    if (id) {
      method = "put";
      url = "subscription-plan";
      value = {
        ...form,
        pricing: [OneMonth, ThreeMonth, TwelveMonth],
        id: id,
      };
    } else {
      delete value.id;
    }
    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success == true || res?.code == 200) {
        toast?.success(res?.message);
        history("/plans");
        loader(false);
      } else {
        loader(false);
        toast?.error(res?.message);
      }
    });
  };

  return (
    <>
      <Layout>
        <form onSubmit={handleSubmit}>
          <div className="pprofile1">
            <div className="flex items-center mb-8">
              <Tooltip placement="top" title="Back">
                <Link
                  to="/plans"
                  className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border  transition-all   mr-3"
                >
                  <i className="fa fa-angle-left text-lg"></i>
                </Link>
              </Tooltip>
              <div>
                <h3 className="text-base font-medium text-[#0A0D14]">
                  {id ? "Edit" : "Add"} Subscription Plan
                </h3>
                <p class="text-sm font-normal text-[#75757A]">
                  Here you can see all about your Plan
                </p>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-7">
                <label>
                  Name<span className="star">*</span>
                </label>
                <input
                  type="text"
                  className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                  value={form.name}
                  onChange={(e) => setform({ ...form, name: e.target.value })}
                />
                {submitted && !form.name ? (
                  <div className="text-danger text-[12px] mt-[3px]">
                    Name is Required
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {/* <div className="col-span-12 md:col-span-6">
                <label>
                  Recommended<span className="star">*</span>
                </label>
                <div className="custom_dropdown">
                  <SelectDropdown
                    isSingle={true}
                    id="statusDropdown"
                    displayValue="name"
                    placeholder="Select Recommendation"
                    intialValue={form.recomended}
                    result={(e) => {
                      setform({ ...form, recomended: e.value });
                    }}
                    options={requiredModel.list}
                  />
                  {submitted && !form.recomended ? (
                    <div className="text-danger text-[12px] mt-[3px]">
                      Recommended is Required
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <label>
                  Trail Period<span className="star">*</span>
                </label>
                <div className="custom_dropdown">
                  <SelectDropdown
                    isSingle={true}
                    id="statusDropdown"
                    displayValue="name"
                    placeholder="Select Days"
                    intialValue={form.trial_period_days}
                    result={(e) => {
                      setform({ ...form, trial_period_days: e.value });
                    }}
                    options={trailPeriodDays}
                  />
                  {submitted && !form.trial_period_days ? (
                    <div className="text-danger text-[12px] mt-[3px]">
                      Trial days are Required
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div> */}
              <div className="col-span-12 md:col-span-7">
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 md:col-span-4">
                    <h5 className="monthTerm">
                      Monthly<span className="star">*</span>
                    </h5>
                    <div className="row">
                      <div className="col-spam-12  pl-3 mb-3">
                        <label>
                          Price
                          <span className="star">*</span>
                        </label>
                        <input
                          className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                          value={ "$" + " " + OneMonth?.unit_amount || ""}
                          maxLength="10"
                          onChange={(e) => {
                            setOneMonth({
                              ...OneMonth,
                              unit_amount: methodModel.isNumber(e),
                            });
                          }}
                        />
                        {submitted && !OneMonth.unit_amount ? (
                          <div className="text-danger text-[12px] mt-[3px]">
                            {" "}
                            Monthly price is required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-4">
                    <h5 className="monthTerm">
                      Quarterly<span className="star">*</span>
                    </h5>
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12 pl-3 mb-3">
                        <label>
                          Price
                          <span className="star">*</span>
                        </label>
                        <input
                          className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                          value={ "$" + " " + ThreeMonth?.unit_amount || ""}
                          maxLength="10"
                          onChange={(e) => {
                            setThreeMonth({
                              ...ThreeMonth,
                              unit_amount: methodModel.isNumber(e),
                            });
                          }}
                        />
                        {submitted && !ThreeMonth.unit_amount ? (
                          <div className="text-danger text-[12px] mt-[3px]">
                            {" "}
                            Quartely price is required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-4">
                    <h5 className="monthTerm">
                      Yearly<span className="star">*</span>
                    </h5>
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12  pl-3 mb-3">
                        <label>
                          Price
                          <span className="star">*</span>
                        </label>
                        <input
                          className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                          value={ "$" + " " + TwelveMonth?.unit_amount}
                          maxLength="10"
                          onChange={(e) => {
                            setTwelveMonth({
                              ...TwelveMonth,
                              unit_amount: methodModel.isNumber(e),
                            });
                          }}
                        />
                        {submitted && !TwelveMonth.unit_amount ? (
                          <div className="text-danger text-[12px] mt-[3px]">
                            {" "}
                            Yearly price is required
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
               
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-7">
                <FormControl
                  type="editor"
                  name="description"
                  label="Monthly Description"
                  value={form.descriptionMonthly}
                  onChange={(e) => setform({ ...form, descriptionMonthly: e })}
                />
              </div>
              <div className="col-span-12 md:col-span-7">
                <FormControl
                  type="editor"
                  name="description"
                  label="Quarterly Description"
                  value={form.descriptionQuarterly}
                  onChange={(e) => setform({ ...form, descriptionQuarterly: e })}
                />
              </div>

              <div className="col-span-12 md:col-span-7">
                <FormControl
                  type="editor"
                  name="description"
                  label="Yearly Description"
                  value={form.descriptionYearly}
                  onChange={(e) => setform({ ...form, descriptionYearly: e })}
                />
              </div>
            

              {/* <div className="col-span-12 md:col-span-12">
                <hr className="borderHr" />
                <h5 className="monthTerm">
                  Term: 6 Months<span className="star">*</span>
                </h5>
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-spam-12 md:col-span-4 pl-3 mb-3">
                    <label>
                      Price{" "}
                      <span className="text-uppercase">
                        {SixMonth?.unit_amount}
                      </span>
                      <span className="star">*</span>
                    </label>
                    <input
                      className="relative  border bg-white w-full rounded-lg h-10 flex items-center gap-2 overflow-hidden px-2"
                      value={SixMonth?.unit_amount}
                      maxLength="10"
                      onChange={(e) => {
                        setSixMonth({
                          ...SixMonth,
                          unit_amount: methodModel.isNumber(e),
                        });
                      }}
                    />
                    {submitted && !SixMonth.unit_amount ? (
                      <div className="text-danger text-[12px] mt-[3px]">
                        {" "}
                        six month price is required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div> */}

              {/* <div className="col-span-12 md:col-span-12">
                <hr className="borderHr" />
                <div className="mt-3 mb-6">
                  <h5 className="text-2xl font-semibold text-[#111827]">
                    Features{" "}
                  </h5>
                </div>
                <div className="grid grid-cols-12 gap-4">
                  <div className="mb-3 col-spam-12 md:col-span-4">
                    {features.map((item, index) => {
                      return (
                        <>
                          <div
                            className={`col-spam-12 md:col-span-11 mt-2 ml-2`}
                          >
                            <label class="form-check-label pointer">
                              <input
                                class="form-check-input w-[15px] h-[15px] relative top-[2px]"
                                id="red-checkbox"
                                type="checkbox"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedFeatures([
                                      ...SelectedFeature,
                                      { id: `${item._id}` },
                                    ]);
                                  } else {
                                    setSelectedFeatures(
                                      SelectedFeature.filter(
                                        (feature) => feature?.id != item._id
                                      )
                                    );
                                  }
                                }}
                                checked={isFeatureSelected(item?._id)}
                              />
                              {item.name}
                            </label>
                          </div>
                        </>
                      );
                    })}
                    {submitted && SelectedFeature?.length == 0 ? (
                      <div className="text-danger text-[12px] mt-[3px]">
                        {" "}
                        Features are required
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div> */}
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className=" text-[#222] bg-[#545454] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default AddEditPlan;
