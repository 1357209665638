import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import "./style.scss";
import { Link } from "react-router-dom";
import AuthLayout from "../../../components/authlayout";
import { toast } from "react-toastify";

const Forgotpassword = () => {
  const history = useNavigate();
  const [form, setForm] = useState({ email: "" });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history("/dashboard");
    }
  }, []);

  const hendleSubmit = (e: any) => {
    e.preventDefault();
    loader(true);
    ApiClient.post("forgot/password", form).then((res) => {
      if (res.success == true || res?.code == 200) {
        history("/login");
        toast.success(res.message);
        loader(false);
      } else {
        loader(false);
        toast?.error(res?.message);
      }
    });
  };
  return (
    <>
      <AuthLayout>
        <form className="w-11/12 md:w-7/12	mx-auto" onSubmit={hendleSubmit}>
          <div className="">
            <h1 className="text-[24px] font-semibold text-black">
              Forgot Passowrd
            </h1>
            <span className="flex w-10 h-1bg-[#130264] mt-1"></span>
          </div>
          <p className="text-[20px] font-normal text-[#222] mt-4">
            {" "}
            No worries! Just enter your email and we’ll send you a reset
            password link.
          </p>
          <div className=" mt-5">
            <div className="inputWrapper">
              <input
                type="email"
                className="mb-4 w-full text-sm text-[#222]  h-16 p-3 flex items-center gap-2 overflow-hidden bg-transparent border border-[#D9D9D9] rounded-[14px]"
                placeholder="Email*"
                value={form.email}
                required
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
            </div>
          </div>

          <div className="flex items-center justify-center mt-6">
            <button
              type="submit"
              className="h-11 rounded-[10px] w-full font-semibold text-center text-white   hover:opacity-80 transition-all"
            >
              Send Recovery Email
            </button>
          </div>

          <p className="text-[#222] text-center font-normal text-[14px] mt-4">
            {" "}
            Just Remember?
            <Link
              className="text-[#ffd6b6] text-[14px] !font-semibold"
              to="/login"
            >
              {" "}
              Sign In
            </Link>
          </p>
        </form>
      </AuthLayout>
    </>
  );
};

export default Forgotpassword;
