import React, { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Html from "./html";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Shared from "./Shared";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const SubAdmin = () => {
  const user = useSelector((state) => state.user);
  const history = useNavigate();
  const searchState = { data: "" };
  const [filters, setFilter] = useState({ page: 1, count: 10, search: "" });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({ ...filters, search: searchState.data });
      getData({ search: searchState.data, page: 1 });
    }
  }, []);
  const getData = (p = {}) => {
    loader(true);
    let filter = { ...filters, ...p };
    ApiClient.get(Shared.listApi, filter).then((res) => {
      if (res.success == true || res?.code == 200) {
        setData(
          res.data.map((itm) => {
            itm.id = itm.id;
            return itm;
          })
        );
        setTotal(res.total);
      }
      loader(false);
    });
  };

  const clear = () => {
    let f = {
      groupId: "",
      search: "",
      status: "",
      page: 1,
    };
    setFilter({ ...filters, ...f });
    getData({ ...f });
  };

  const filter = (p = {}) => {
    let f = {
      page: 1,
      ...p,
    };
    setFilter({ ...filters, ...f });
    getData({ ...f });
  };
  const deleteItem = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete this staff`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ffd6b6",
      cancelButtonColor: "#d52121",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.put(`${Shared.deleteApi}?id=${id}`).then((res) => {
          if (res.success == true || res?.code == 200) {
            toast?.success(res?.message);
            clear();
          }
          loader(false);
        });
      }
    });
  };

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  };
  const count = (e) => {
    setFilter({ ...filters, count: e });
    getData({ ...filters, count: e });
  };
  const changestatus = (e) => {
    setFilter({ ...filters, status: e, page: 1 });
    getData({ status: e, page: 1 });
  };

  const statusChange = (itm) => {
    let status = "active";
    if (itm.status == "active") status = "deactive";
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to ${
        status == "active" ? "Activate" : "Deactivate"
      } this staff?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ffd6b6",
      cancelButtonColor: "#d52121",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        loader(true);
        ApiClient.put(Shared.statusApi, { id: itm.id, status }).then((res) => {
          if (res.success == true || res?.code == 200) {
            toast?.success(res?.message);
            loader(false);
            getData();
          } else {
            loader(false);
          }
        });
      }
    });
  };

  const edit = (id) => {
    history(`/${Shared.url}/edit/${id}`);
  };

  const view = (id) => {
    let url = `/${Shared.url}/detail/${id}`;
    history(url);
  };

  return (
    <>
      <Html
        edit={edit}
        view={view}
        clear={clear}
        count={count}
        pageChange={pageChange}
        deleteItem={deleteItem}
        filters={filters}
        setFilter={setFilter}
        filter={filter}
        data={data}
        total={total}
        statusChange={statusChange}
        changestatus={changestatus}
      />
    </>
  );
};

export default SubAdmin;
