const Shared = {
  check: "Customers",
  title: "Users",
  addTitle: "User",
  url: "users",
  addApi: "add/user",
  editApi: "edit/profile",
  detailApi: "user/details",
  listApi: "users/list",
  statusApi: "user/statusChange",
  deleteApi: "user/delete",
};

export default Shared;
