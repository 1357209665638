const Shared = {
  check: "Customers",
  title: "Staff Management",
  addTitle: "Staff",
  url: "subadmin",
  addApi: "add/user",
  editApi: "edit/profile",
  detailApi: "user/details",
  listApi: "subAdminlist",
  statusApi: "user/statusChange",
  deleteApi: "user/delete",
};

export default Shared;
